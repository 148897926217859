'use client';

import { cx } from 'class-variance-authority';
import { SocialIcon } from 'react-social-icons';
import { Separator } from '@/components/ui/separator';
import { ContentfulGlobalSettings } from '@/lib/types/contentful/ContentfulGlobalSettings';
interface FooterProps extends Pick<ContentfulGlobalSettings, 'sys' | 'siteName' | 'footerEmail' | 'footerPhone' | 'footerSocialLinks' | 'footerText' | 'privacyPolicy' | 'eula'> {}
const classes = {
  link: 'no-underline hover:text-white hover:underline',
  list: '[&_li]:font-mono [&_a]:font-mono flex flex-col text-left sm:gap-1 sm:text-right'
};
export function Footer({
  sys,
  footerEmail,
  siteName,
  footerPhone,
  footerSocialLinks,
  footerText,
  privacyPolicy,
  eula
}: FooterProps) {
  return <footer className="mt-0 flex flex-col bg-secondary-readable text-white">
      <div className="container px-4 sm:px-8">
        <div className="flex flex-col justify-between gap-5 py-10 sm:flex-row xl:px-0">
          <div className="flex flex-col gap-5 sm:w-7/12">
            <p>{footerText}</p>

            <ul className="[&_li]:font-mono [&_a]:font-mono">
              <li>
                Tel:{' '}
                <a className={classes.link} href={`tel:${footerPhone}`}>
                  {footerPhone}
                </a>
              </li>
              <li>
                Email:{' '}
                <a className={classes.link} href={`mailto:${footerEmail}`}>
                  {footerEmail}
                </a>
              </li>
            </ul>
            <ul className="flex gap-5">
              {footerSocialLinks.map(link => <li key={`${sys.id}-${link}`}>
                  <SocialIcon target="_blank" fgColor={'white'} style={{
                width: 42,
                height: 42
              }} url={link} />
                </li>)}
            </ul>
          </div>
          <div className="sm:w-5/12 mt-10 sm:mt-0">
            <ul className={cx(classes.list, 'mt-5')}>
              {privacyPolicy && <li>
                  <a className={classes.link} href={privacyPolicy.href}>
                    Privacy Policy
                  </a>
                </li>}
              {eula && <li>
                  <a className={classes.link} href={eula.href}>
                    Eula
                  </a>
                </li>}
            </ul>
          </div>
        </div>
      </div>
    </footer>;
}